import React from "react";
import "../../styles/main-page/what-we-do-section.css";
import SocialMediaPhone1 from "../../assets/images/social-media-phone-1.png"
import SocialMediaPhone2 from "../../assets/images/social-media-phone-2.png"
import SocialMediaPhone3 from "../../assets/images/social-media-phone-3.png"
import AudioMusicDiskCover from "../../assets/images/audio-music-disk-cover.png"
import AudioMusicDisk from "../../assets/images/audio-music-disk.png"
import ContentCrationFilm from "../../assets/images/content-creation-film.png"
import MarketingPen from "../../assets/images/marketing-pen.png"
import MarketingCap from "../../assets/images/marketing-cap.png"

export default function WhatWeDoSection() {
	return (
		<section className="what-we-do-section" id="services">
			<h3>What we do</h3>
			<br />
			<div className="heading-wrapper">
				<div className="heading-bar"></div>
				<h1 style={{left: '10px'}}>
					To be exact,
					<br />
					everything
				</h1>
			</div>

			<div className="grid-area">
				<div className="row">
					<div className="row-item social-media">
						Social
						<br />
						Media
						<div className="row-item-preview">
							<img style={{transitionDelay: '0.09s'}} className="social-media-phone phone-1" src={SocialMediaPhone1} alt="Phone mockup" />
							<img style={{transitionDelay: '0.10s'}} className="social-media-phone phone-2" src={SocialMediaPhone2} alt="Phone mockup" />
							<img style={{transitionDelay: '0.11s'}} className="social-media-phone phone-3" src={SocialMediaPhone3} alt="Phone mockup" />
						</div>
					</div>
					<div className="row-item">
						Identity
						<br />
						Creation
					</div>
					<div className="row-item">
						UI
						<br />
						UX
					</div>
				</div>
				<div className="row">
					<div className="row-item audio-music">
						Audio
						<br />& Music
						<div className="row-item-preview">
							<img style={{transitionDelay: '0.09s'}} className="audio-music cover" src={AudioMusicDiskCover} alt="Disk Cover Mockup" />
							<img style={{transitionDelay: '0.13s'}} className="audio-music disk" src={AudioMusicDisk} alt="Vinyl Mockup" />
						</div>
					</div>
					<div className="row-item content-creation">
						Content
						<br />
						Creation
						<div className="row-item-preview">
							<img style={{transitionDelay: '0.09s'}} className="content-creation" src={ContentCrationFilm} alt="Camer Film Mockup" />
						</div>
					</div>
					<div className="row-item marketing">
						Marketing
						<div className="row-item-preview">
							<img style={{transitionDelay: '0.09s'}} className="marketing pen" src={MarketingPen} alt="Pen Mockup" />
							<img style={{transitionDelay: '0.15s'}} className="marketing cap" src={MarketingCap} alt="Pen Cap Mockup" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
