import { createSlice } from "@reduxjs/toolkit";

export const modalsSlice = createSlice({
	name: "modals",
	initialState: {
		workModalState: { open: false, title: null, imgSrc: null },
	},
	reducers: {
		setWorkModalState: (state, action) => {
			state.workModalState.open = action.payload.open;
			state.workModalState.title = action.payload.title;
			state.workModalState.imgSrc = action.payload.imgSrc;
		},
	},
});

export const { setWorkModalState } = modalsSlice.actions;
