import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import MainPage from "./pages/MainPage";
import LetsWorkPage from "./pages/LetsWorkPage";
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					<Route element={<Layout />}>
						<Route index element={<MainPage />} />
						<Route path="/lets-work" element={<LetsWorkPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
