import React from "react";
import "../styles/footer.css";

import InstagramIcon from "../assets/images/instagram-icon.png";
import LinkedinIcon from "../assets/images/linkedin-icon.png";
import BehanceIcon from "../assets/images/behance-icon.png";

export default function Footer() {
	return (
		<div className="footer">
			<div className="list-wrapper desktop">
				<ul>
					<li>
						<a href="/#about">About Us</a>
					</li>
					<li>
						<a href="/#services">Services</a>
					</li>
					<li>
						<a href="/#work">Work</a>
					</li>
					<li>
						<a href="/#team">Team</a>
					</li>
					<li>
						<a href="/#clients">Clients</a>
					</li>
					<li>
						<a href="/#contact">Contact Us</a>
					</li>
				</ul>
			</div>
			<div className="list-wrapper mobile">
				<a href="/#home">Home</a>
				<a href="/#team">Team</a>
				<a href="/#services">Services</a>
				<a href="/#contact">Contact Us</a>
				<a href="/#about">About Us</a>
				<a href="/#work">Work</a>
				<a href="/#clients">Clients</a>
				<div className="lets-work-wrapper">
					<a href="/lets-work">Let's work!</a>
				</div>
			</div>
			<div className="lets-work-wrapper desktop">
				<a href="/lets-work">Let's work!</a>
			</div>

			<div className="socials-wrapper">
				<a
					href="https://www.instagram.com/borzoistudio"
					target="_blank"
					rel="noreferrer"
				>
					<img src={InstagramIcon} alt="Instagram Icon" />
				</a>
				<a
					href="https://www.linkedin.com/company/borzoistudio/"
					target="_blank"
					rel="noreferrer"
				>
					<img src={LinkedinIcon} alt="LinekdIn Icon" />
				</a>
				<a
					href="https://www.behance.net/borzoistudio"
					target="_blank"
					rel="noreferrer"
				>
					<img src={BehanceIcon} alt="Behance Icon" />
				</a>
			</div>
			<div>
				Copyright © {new Date().getFullYear()}. All Rights Reserved.
			</div>
			<div className="blob"></div>
		</div>
	);
}
