import React from "react";
import "../../styles/lets-work-page/brand-form-section.css";

export default function BrandFormSection({ register, errors }) {
	return (
		<section className="brand-form-section">
			<div className="heading-wrapper">
				<div className="heading-bar left"></div>
				<h1>
					Tell Us About
					<br />
					Your Brand
				</h1>
			</div>

			<div className="brand-form">
				<div className="form-input-section">
					<label>
						<span className="asterisc">Brand Name</span>
					</label>
					<div
						className={`input-wrapper ${
							errors.brand?.name ? "error" : ""
						}`}
					>
						<input type="text" {...register("brand.name")} />
					</div>
				</div>
				<div className="form-input-section">
					<label>
						<span className="asterisc">Industry</span>
					</label>
					<div
						className={`input-wrapper ${
							errors.brand?.industry ? "error" : ""
						}`}
					>
						<input type="text" {...register("brand.industry")} />
					</div>
				</div>
				<div className="form-input-section">
					<label>
						Website{" "}
						<span className="sub-label">(if available)</span>
					</label>
					<div className="input-wrapper">
						<input type="text" {...register("brand.website")} />
					</div>
				</div>
				<div className="form-input-section">
					<label>
						Social Media Handle{" "}
						<span className="sub-label">(if available)</span>
					</label>
					<div className="input-wrapper">
						<input
							type="text"
							{...register("brand.social_media_handle")}
						/>
					</div>
				</div>
				<div className="form-input-section colspan-2 textarea-section">
					<label>
						<span className="asterisc">Brand Description</span>
					</label>
					<div
						className={`input-wrapper ${
							errors.brand?.description ? "error" : ""
						}`}
					>
						<textarea {...register("brand.description")}></textarea>
					</div>
				</div>
			</div>
		</section>
	);
}
