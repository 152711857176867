import React, { useState } from "react";
import "../../styles/main-page/our-clients-section.css";
import AzzamClient300Colored from "../../assets/images/clients/Azzam-300x300-Colored.png";
import AzzamClient300Greyscaled from "../../assets/images/clients/Azzam-300x300-Greyscale.png";
import AzzamClient500Colored from "../../assets/images/clients/Azzam-500x500-Colored.png";
import AzzamClient500Greyscaled from "../../assets/images/clients/Azzam-500x500-Greyscale.png";
import DoodaClient300Colored from "../../assets/images/clients/Dooda-300x300-Colored.png";
import DoodaClient300Greyscaled from "../../assets/images/clients/Dooda-300x300-Greyscale.png";
import DoodaClient500Colored from "../../assets/images/clients/Dooda-500x500-Colored.png";
import DoodaClient500Greyscaled from "../../assets/images/clients/Dooda-500x500-Greyscale.png";
import Free2GoClient300Colored from "../../assets/images/clients/Free2Go-300x300-Colored.png";
import Free2GoClient300Greyscaled from "../../assets/images/clients/Free2Go-300x300-Greyscale.png";
import Free2GoClient500Colored from "../../assets/images/clients/Free2Go-500x500-Colored.png";
import Free2GoClient500Greyscaled from "../../assets/images/clients/Free2Go-500x500-Greyscale.png";
import InjazClient300Colored from "../../assets/images/clients/Injaz-300x300-Colored.png";
import InjazClient300Greyscaled from "../../assets/images/clients/Injaz-300x300-Greyscale.png";
import InjazClient500Colored from "../../assets/images/clients/Injaz-500x500-Colored.png";
import InjazClient500Greyscaled from "../../assets/images/clients/Injaz-500x500-Greyscale.png";
import LegacyTrimsClient300Colored from "../../assets/images/clients/LegacyTrims-300x300-Colored.png";
import LegacyTrimsClient300Greyscaled from "../../assets/images/clients/LegacyTrims-300x300-Greyscale.png";
import LegacyTrimsClient500Colored from "../../assets/images/clients/LegacyTrims-500x500-Colored.png";
import LegacyTrimsClient500Greyscaled from "../../assets/images/clients/LegacyTrims-500x500-Greyscale.png";
import LorealClient300Colored from "../../assets/images/clients/Loreal-300x300-Colored.png";
import LorealClient300Greyscaled from "../../assets/images/clients/Loreal-300x300-Greyscale.png";
import LorealClient500Colored from "../../assets/images/clients/Loreal-500x500-Colored.png";
import LorealClient500Greyscaled from "../../assets/images/clients/Loreal-500x500-Greyscale.png";
import NadaDebsClient300Colored from "../../assets/images/clients/NadaDebs-300x300-Colored.png";
import NadaDebsClient300Greyscaled from "../../assets/images/clients/NadaDebs-300x300-Greyscale.png";
import NadaDebsClient500Colored from "../../assets/images/clients/NadaDebs-500x500-Colored.png";
import NadaDebsClient500Greyscaled from "../../assets/images/clients/NadaDebs-500x500-Greyscale.png";
import NDHRClient300Colored from "../../assets/images/clients/NDHR-300x300-Colored.png";
import NDHRClient300Greyscaled from "../../assets/images/clients/NDHR-300x300-Greyscale.png";
import NDHRClient500Colored from "../../assets/images/clients/NDHR-500x500-Colored.png";
import NDHRClient500Greyscaled from "../../assets/images/clients/NDHR-500x500-Greyscale.png";
import NoknokClient300Colored from "../../assets/images/clients/Noknok-300x300-Colored.png";
import NoknokClient300Greyscaled from "../../assets/images/clients/Noknok-300x300-Greyscale.png";
import NoknokClient500Colored from "../../assets/images/clients/Noknok-500x500-Colored.png";
import NoknokClient500Greyscaled from "../../assets/images/clients/Noknok-500x500-Greyscale.png";
import NouvoClient300Colored from "../../assets/images/clients/Nouvo-300x300-Colored.png";
import NouvoClient300Greyscaled from "../../assets/images/clients/Nouvo-300x300-Greyscale.png";
import NouvoClient500Colored from "../../assets/images/clients/Nouvo-500x500-Colored.png";
import NouvoClient500Greyscaled from "../../assets/images/clients/Nouvo-500x500-Greyscale.png";
import OneClient300Colored from "../../assets/images/clients/One-300x300-Colored.png";
import OneClient300Greyscaled from "../../assets/images/clients/One-300x300-Greyscale.png";
import OneClient500Colored from "../../assets/images/clients/One-500x500-Colored.png";
import OneClient500Greyscaled from "../../assets/images/clients/One-500x500-Greyscale.png";
import RaficAntoniosClient300Colored from "../../assets/images/clients/RaficAntonios-300x300-Colored.png";
import RaficAntoniosClient300Greyscaled from "../../assets/images/clients/RaficAntonios-300x300-Greyscale.png";
import RaficAntoniosClient500Colored from "../../assets/images/clients/RaficAntonios-500x500-Colored.png";
import RaficAntoniosClient500Greyscaled from "../../assets/images/clients/RaficAntonios-500x500-Greyscale.png";
import SahemClient300Colored from "../../assets/images/clients/Sahem-300x300-Colored.png";
import SahemClient300Greyscaled from "../../assets/images/clients/Sahem-300x300-Greyscale.png";
import SahemClient500Colored from "../../assets/images/clients/Sahem-500x500-Colored.png";
import SahemClient500Greyscaled from "../../assets/images/clients/Sahem-500x500-Greyscale.png";
import ShivalilaClient300Colored from "../../assets/images/clients/Shivalila-300x300-Colored.png";
import ShivalilaClient300Greyscaled from "../../assets/images/clients/Shivalila-300x300-Greyscale.png";
import ShivalilaClient500Colored from "../../assets/images/clients/Shivalila-500x500-Colored.png";
import ShivalilaClient500Greyscaled from "../../assets/images/clients/Shivalila-500x500-Greyscale.png";
import SkaleClient300Colored from "../../assets/images/clients/Skale-300x300-Colored.png";
import SkaleClient300Greyscaled from "../../assets/images/clients/Skale-300x300-Greyscale.png";
import SkaleClient500Colored from "../../assets/images/clients/Skale-500x500-Colored.png";
import SkaleClient500Greyscaled from "../../assets/images/clients/Skale-500x500-Greyscale.png";
import TransglobalClient300Colored from "../../assets/images/clients/Transglobal-300x300-Colored.png";
import TransglobalClient300Greyscaled from "../../assets/images/clients/Transglobal-300x300-Greyscale.png";
import TransglobalClient500Colored from "../../assets/images/clients/Transglobal-500x500-Colored.png";
import TransglobalClient500Greyscaled from "../../assets/images/clients/Transglobal-500x500-Greyscale.png";
import TransparencyClient300Colored from "../../assets/images/clients/Transparency-300x300-Colored.png";
import TransparencyClient300Greyscaled from "../../assets/images/clients/Transparency-300x300-Greyscale.png";
import TransparencyClient500Colored from "../../assets/images/clients/Transparency-500x500-Colored.png";
import TransparencyClient500Greyscaled from "../../assets/images/clients/Transparency-500x500-Greyscale.png";
import TravelnClient300Colored from "../../assets/images/clients/Traveln-300x300-Colored.png";
import TravelnClient300Greyscaled from "../../assets/images/clients/Traveln-300x300-Greyscale.png";
import TravelnClient500Colored from "../../assets/images/clients/Traveln-500x500-Colored.png";
import TravelnClient500Greyscaled from "../../assets/images/clients/Traveln-500x500-Greyscale.png";
import VoodooClient300Colored from "../../assets/images/clients/Voodoo-300x300-Colored.png";
import VoodooClient300Greyscaled from "../../assets/images/clients/Voodoo-300x300-Greyscale.png";
import VoodooClient500Colored from "../../assets/images/clients/Voodoo-500x500-Colored.png";
import VoodooClient500Greyscaled from "../../assets/images/clients/Voodoo-500x500-Greyscale.png";
import WearwolfClient300Colored from "../../assets/images/clients/Wearwolf-300x300-Colored.png";
import WearwolfClient300Greyscaled from "../../assets/images/clients/Wearwolf-300x300-Greyscale.png";
import WearwolfClient500Colored from "../../assets/images/clients/Wearwolf-500x500-Colored.png";
import WearwolfClient500Greyscaled from "../../assets/images/clients/Wearwolf-500x500-Greyscale.png";
import WideaClient300Colored from "../../assets/images/clients/Widea-300x300-Colored.png";
import WideaClient300Greyscaled from "../../assets/images/clients/Widea-300x300-Greyscale.png";
import WideaClient500Colored from "../../assets/images/clients/Widea-500x500-Colored.png";
import WideaClient500Greyscaled from "../../assets/images/clients/Widea-500x500-Greyscale.png";
import WoodenBakeryClient300Colored from "../../assets/images/clients/WoodenBakery-300x300-Colored.png";
import WoodenBakeryClient300Greyscaled from "../../assets/images/clients/WoodenBakery-300x300-Greyscale.png";
import WoodenBakeryClient500Colored from "../../assets/images/clients/WoodenBakery-500x500-Colored.png";
import WoodenBakeryClient500Greyscaled from "../../assets/images/clients/WoodenBakery-500x500-Greyscale.png";

const CLIENTS = [
	{ name: "Azzam", img300: {colored: AzzamClient300Colored, greyscaled: AzzamClient300Greyscaled}},
	{ name: "Dooda", img300: {colored: DoodaClient300Colored, greyscaled: DoodaClient300Greyscaled}},
	{ name: "Free2Go", img300: {colored: Free2GoClient300Colored, greyscaled: Free2GoClient300Greyscaled}},
	{ name: "Injaz", img300: {colored: InjazClient300Colored, greyscaled: InjazClient300Greyscaled}},
	{ name: "LegacyTrims", img300: {colored: LegacyTrimsClient300Colored, greyscaled: LegacyTrimsClient300Greyscaled}},
	{ name: "L'Oreal", img300: {colored: LorealClient300Colored, greyscaled: LorealClient300Greyscaled}},
	{ name: "Nada Debs", img300: {colored: NadaDebsClient300Colored, greyscaled: NadaDebsClient300Greyscaled}},
	{ name: "NDHR", img300: {colored: NDHRClient300Colored, greyscaled: NDHRClient300Greyscaled}},
	{ name: "Noknok", img300: {colored: NoknokClient300Colored, greyscaled: NoknokClient300Greyscaled}},
	{ name: "Nouvo", img300: {colored: NouvoClient300Colored, greyscaled: NouvoClient300Greyscaled}},
	{ name: "One", img300: {colored: OneClient300Colored, greyscaled: OneClient300Greyscaled}},
	{ name: "Rafic Antonios", img300: {colored: RaficAntoniosClient300Colored, greyscaled: RaficAntoniosClient300Greyscaled}},
	{ name: "LegacyTrims", img300: {colored: LegacyTrimsClient300Colored, greyscaled: LegacyTrimsClient300Greyscaled}},
	{ name: "Sahem", img300: {colored: SahemClient300Colored, greyscaled: SahemClient300Greyscaled}},
	{ name: "Shivalila", img300: {colored: ShivalilaClient300Colored, greyscaled: ShivalilaClient300Greyscaled}},
	{ name: "Skale", img300: {colored: SkaleClient300Colored, greyscaled: SkaleClient300Greyscaled}},
	{ name: "Transglobal", img300: {colored: TransglobalClient300Colored, greyscaled: TransglobalClient300Greyscaled}},
	{ name: "Transparency", img300: {colored: TransparencyClient300Colored, greyscaled: TransparencyClient300Greyscaled}},
	{ name: "Traveln", img300: {colored: TravelnClient300Colored, greyscaled: TravelnClient300Greyscaled}},
	{ name: "Voodoo", img300: {colored: VoodooClient300Colored, greyscaled: VoodooClient300Greyscaled}},
	{ name: "Wearwolf", img300: {colored: WearwolfClient300Colored, greyscaled: WearwolfClient300Greyscaled}},
	{ name: "Widea", img300: {colored: WideaClient300Colored, greyscaled: WideaClient300Greyscaled}},
	{ name: "WoodenBakery", img300: {colored: WoodenBakeryClient300Colored, greyscaled: WoodenBakeryClient300Greyscaled}},

	{ name: "Azzam", img300: {colored: AzzamClient300Colored, greyscaled: AzzamClient300Greyscaled}},
	{ name: "Dooda", img300: {colored: DoodaClient300Colored, greyscaled: DoodaClient300Greyscaled}},
	{ name: "Free2Go", img300: {colored: Free2GoClient300Colored, greyscaled: Free2GoClient300Greyscaled}},
	{ name: "Injaz", img300: {colored: InjazClient300Colored, greyscaled: InjazClient300Greyscaled}},
	{ name: "LegacyTrims", img300: {colored: LegacyTrimsClient300Colored, greyscaled: LegacyTrimsClient300Greyscaled}},
	{ name: "L'Oreal", img300: {colored: LorealClient300Colored, greyscaled: LorealClient300Greyscaled}},
	{ name: "Nada Debs", img300: {colored: NadaDebsClient300Colored, greyscaled: NadaDebsClient300Greyscaled}},
	{ name: "NDHR", img300: {colored: NDHRClient300Colored, greyscaled: NDHRClient300Greyscaled}},
	{ name: "Noknok", img300: {colored: NoknokClient300Colored, greyscaled: NoknokClient300Greyscaled}},
	{ name: "Nouvo", img300: {colored: NouvoClient300Colored, greyscaled: NouvoClient300Greyscaled}},
	{ name: "One", img300: {colored: OneClient300Colored, greyscaled: OneClient300Greyscaled}},
	{ name: "Rafic Antonios", img300: {colored: RaficAntoniosClient300Colored, greyscaled: RaficAntoniosClient300Greyscaled}},
	{ name: "LegacyTrims", img300: {colored: LegacyTrimsClient300Colored, greyscaled: LegacyTrimsClient300Greyscaled}},
	{ name: "Sahem", img300: {colored: SahemClient300Colored, greyscaled: SahemClient300Greyscaled}},
	{ name: "Shivalila", img300: {colored: ShivalilaClient300Colored, greyscaled: ShivalilaClient300Greyscaled}},
	{ name: "Skale", img300: {colored: SkaleClient300Colored, greyscaled: SkaleClient300Greyscaled}},
	{ name: "Transglobal", img300: {colored: TransglobalClient300Colored, greyscaled: TransglobalClient300Greyscaled}},
	{ name: "Transparency", img300: {colored: TransparencyClient300Colored, greyscaled: TransparencyClient300Greyscaled}},
	{ name: "Traveln", img300: {colored: TravelnClient300Colored, greyscaled: TravelnClient300Greyscaled}},
	{ name: "Voodoo", img300: {colored: VoodooClient300Colored, greyscaled: VoodooClient300Greyscaled}},
	{ name: "Wearwolf", img300: {colored: WearwolfClient300Colored, greyscaled: WearwolfClient300Greyscaled}},
	{ name: "Widea", img300: {colored: WideaClient300Colored, greyscaled: WideaClient300Greyscaled}},
	{ name: "WoodenBakery", img300: {colored: WoodenBakeryClient300Colored, greyscaled: WoodenBakeryClient300Greyscaled}},
];

export default function OurClientsSection() {
	return (
		<section className="our-clients-section" id="clients">
			<h3>Our clients</h3>
			<br />
			<div className="heading-wrapper">
				<div
					className="heading-bar left"
					style={{ transform: "scaleX(1.15)" }}
				></div>
				<h1 style={{left: '10px'}}>
					People who
					<br />
					trust us
				</h1>
			</div>

			<div className="scroll-animation-area">
				{/* <div className="arrow left">
					<img src={ArrowLeft} alt="Arrow left" />
				</div>
				<div className="arrow right">
					<img src={ArrowLeft} alt="Arrow left" />
				</div> */}
				<div className="scroll-animation-wrapper">
					<div className="scroll-container">
						{CLIENTS.map((client, index) => {
							return <div className="scroll-item" key={index}>
								<img data-color="colored"
									src={client.img300.colored}
									alt="Portrait of Dominic"
								/>
								<img
									data-color="greyscale"
									src={client.img300.greyscaled}
									alt="Portrait of Dominic"
								/>
							</div>;
						})}
					</div>
				</div>
			</div>
		</section>
	);
}
