import React, { useCallback, useEffect } from "react";
import "../../styles/modal/our-work-modal.css";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { setWorkModalState } from "../../redux/slices/modals-slice";

export default function OurWorkModal({ title, imgSrc }) {
	const dispatch = useDispatch();

	const onCLose = useCallback(() => {
		dispatch(setWorkModalState({ open: false }));
	}, []);


	useEffect(() => {
		window.document.body.style.overflow = "hidden"

		return () => {
			window.document.body.style.overflow = "unset"
		}
	}, [])

	return (
		<div className="our-work-modal">
			<div className="backdrop" onClick={onCLose}></div>
			<div className="close-btn">
				{/*<Button onClick={onCLose} style={{ width: "max-width" }}>
					<IoClose size={22} /> Close
				</Button>*/}
			<div onClick={onCLose}><IoClose size={22} /> Close</div>
			</div>
			<div className="modal-content">
				<img src={imgSrc} alt={title} />
			</div>
		</div>
	);
}
