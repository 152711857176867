import React, { useEffect, useRef } from "react";
import "../../styles/main-page/who-are-we-section.css";
import SphereImage from "../../assets/images/sphere.png";

const FRICTION = 50;

export default function WhoAreWeSection() {
	const SphereRef = useRef();

	const parallaxFunction = (e) => {
		const sphereRatio = SphereRef.current.getAttribute("data-ratio");
		let x = (e.clientX * sphereRatio) / FRICTION;
		let y = (e.clientY * sphereRatio) / FRICTION;
		SphereRef.current.style.transform = `translateX(${x}px) translateY(calc(${y}px - 50%))`;
	};

	useEffect(() => {
		document.addEventListener("mousemove", parallaxFunction);

		return () => {
			document.removeEventListener("mousemove", parallaxFunction);
		};
	}, []);

	return (
		<section className="who-are-we-section" id="about">
			<h3>Who We Are</h3>
			<br />
			<h1>
				We create
				<br />
				things
			</h1>
			<div className="text-wrapper">
				<div className="bar"></div>
				<p>
					Step into the world of Borzoi Studio, where creativity
					dances freely and innovation knows no bounds!
					<br />
					<br />
					Imagine your brand as a vibrant character in a story we're
					itching to tell. Whether it's crafting an identity that
					winks at your essence or whipping up social media magic that
					makes your audience grin from ear to ear, we've got the
					touch your brand deserves.
					<br />
					<br />
					Welcome to Borzoi Studio, where your vision comes to life.
					Let's create something awesome together!
				</p>
			</div>
			<div className="square-background">
				<div></div>
				<div></div>
				<div className="sphere" data-ratio="2" ref={SphereRef}>
					<img src={SphereImage} alt="Sphere Illustration" />
				</div>
			</div>
		</section>
	);
}
