import React from "react";
import "../styles/custom-checkbox.css";

export default function CustomCheckbox() {
	return (
		<div className="custom-checkbox">
			<svg viewBox="0 0 50 50">
				<path
					d="M5 30 L20 45 L45 5"
					strokeWidth="8"
					stroke="#fff"
					fill="none"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
}
