import React from "react";
import "../../styles/main-page/meet-the-team-section.css";
import Dominic500 from "../../assets/images/Dominic-500.png";
import Dominic900 from "../../assets/images/Dominic-900.png";
import Elie500 from "../../assets/images/Elie-500.png";
import Elie900 from "../../assets/images/Elie-900.png";

export default function MeetTheTeamSection() {
	return (
		<section className="meet-the-team-section" id="team">
			<div className="right-layout">
				<div className="content">
					<h3>Meet the founders</h3>
					<br />
					<h1>
						Dominic
						<br />
						Beainy
					</h1>
					<div className="text-wrapper">
						<div className="bar"></div>
						<p>
							Step into the world of Borzoi Studio, where creativity
							dances freely and innovation knows no bounds!
							<br />
							<br />
							Imagine your brand as a vibrant character in a story
							we're itching to tell. Whether it's crafting an identity
							that winks at your essence or whipping up social media
							magic that makes your audience grin from ear to ear,
							we've got the touch your brand deserves.
							<br />
						</p>
					</div>
				</div>
				<div className="square-background">
					<div className="portrait-wrapper">
						<img
							src={Dominic900}
							srcSet={`${Dominic500} 500w, ${Dominic900} 900w`}
							alt="Portrait of Dominic"
						/>
					</div>
					<div></div>
				</div>
				<div className="mobile-paragraph">Step into the world of Borzoi Studio, where creativity dances freely and innovation knows no bounds! Imagine your brand as a vibrant character in a story we're itching to tell. Whether it's crafting an identity that winks at your essence or whipping up social media magic that makes your audience grin from ear to ear, we've got the touch your brand deserves.
				</div>
			</div>

			<div className="left-layout">
			<div className="content">
				<h3>Meet the founders</h3>
				<br />
				<h1>
					Elie
					<br />
					Hachem
				</h1>
				<div className="text-wrapper">
					<div className="bar"></div>
					<p>
						Hello there!
						<br />
						I'm Elie Hachem, a digital and brand designer with a passion for bringing creativity to life.
						<br /><br />
						With a wealth of experience in the ever evolving world of design, I've honed my skills to craft 
						compelling visual stories that resonate with audiences. From shaping brand identities to navigating 
						the digital landscape, I thrive on the intersection of innovation and aesthetics.
						<br />
						When I'm not designing, you will find me making music, or ordering food!
					</p>
				</div>
				</div>
				<div className="square-background">
					<div className="portrait-wrapper">
						<img
							src={Elie900}
							srcSet={`${Elie500} 500w, ${Elie900} 900w`}
							alt="Portrait of Elie"
						/>
					</div>
					<div></div>
				</div>
				<div className="mobile-paragraph">
					Hello there!
					<br />
					I'm Elie Hachem, a digital and brand designer with a passion for bringing creativity to life.
					<br />
					With a wealth of experience in the ever evolving world of design, I've honed my skills to craft 
					compelling visual stories that resonate with audiences. From shaping brand identities to navigating 
					the digital landscape, I thrive on the intersection of innovation and aesthetics. 
					When I'm not designing, you will find me making music, or ordering food!
				</div>
			</div>
		</section>
	);
}
