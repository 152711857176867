import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles/main-page/contact-us-section.css";
import { render } from "@react-email/render";
import ContactEmailComponent from "../email/ContactEmailComponent";
import ConfettiExplosion from "react-confetti-explosion";
import { PuffLoader } from "react-spinners";
import emailjs from '@emailjs/browser';

const EMAIL_SERVICE_ID = "service_ni8b8sq"
const TEMPLATE_ID = "contact_us_form"
const PUBLIC_KEY = "LEZdWIPHoURvozcUc"

export default function ContactUsSection() {
	const [isSuccessfull, setIsSuccessfull] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [submitButtonStatus, setSubmitButtonStatus] = useState("Send")
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		const { full_name, email, message } = data;
		
		setIsLoading(true);
		emailjs
	      .send(
	        EMAIL_SERVICE_ID,
	        TEMPLATE_ID,
	        {
	          client_name: full_name,
	          email: email,
	          message: message || "No message",
	        },
	        PUBLIC_KEY,
	      ).then(() => {
	      	setIsLoading(false);
			setIsSuccessfull(true);
			setSubmitButtonStatus("Email sent!")
			reset();
		}).catch(()=>{
			setIsLoading(false);
			alert("Something went wrong sending the email.");
		})
		// const emailHtml = render(<ContactEmailComponent data={data} />);
		// const emailParams = {
		// 	Source: "hello@borzoistudio.com",
		// 	Destination: {
		// 		ToAddresses: ["elienzgheib@gmail.com"],
		// 	},
		// 	Message: {
		// 		Body: {
		// 			Html: {
		// 				Charset: "UTF-8",
		// 				Data: emailHtml,
		// 			},
		// 		},
		// 		Subject: {
		// 			Charset: "UTF-8",
		// 			Data: `${data.full_name} contacted you!`,
		// 		},
		// 	},
		// };
	};

	return (
		<section className="contact-us-section" id="contact">
			<h3>Contact us</h3>
			<br />
			<div className="heading-wrapper">
				<h1>
					Send us your
					<br />
					inquiries!
				</h1>
				<div
					className="heading-bar right"
					style={{ transform: "scaleX(1.2)" }}
				></div>
			</div>

			<form className="contact-us-form" onSubmit={handleSubmit(onSubmit)}>
				<div className="input-wrapper">
					<input
						type="text"
						placeholder="Full name"
						name="full_name"
						{...register("full_name", {
							required: {
								value: true,
								message: "Full name is required",
							},
						})}
					/>
				</div>
				{errors.full_name && (
					<div className="error-message">
						{errors.full_name.message}
					</div>
				)}
				<div className="input-wrapper">
					<input
						type="text"
						placeholder="Email"
						name="email"
						{...register("email", {
							required: {
								value: true,
								message: "Email is required",
							},
							pattern: {
								value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
								message: "Invalid email address",
							},
						})}
					/>
				</div>
				{errors.email && (
					<div className="error-message">{errors.email.message}</div>
				)}
				<div className="input-wrapper">
					<textarea
						placeholder="Message"
						name="message"
						{...register("message")}
					></textarea>
				</div>
				<div className="form-footer">
					<div className="submit-btn-wrapper">
						<input
							type="submit"
							value={submitButtonStatus}
							id="submit-btn"
							style={{color: isLoading ? 'transparent':'#fff'}}
						/>
						<div className="loader-wrapper">
							<PuffLoader
								size={45}
								color="#fff"
								loading={isLoading}
								speedMultiplier={1.1}
							/>
						</div>
					</div>
					{isSuccessfull && (
						<ConfettiExplosion
							colors={[
								"#dd9c94",
								"#fcb3aa",
								"#111",
								"#eee",
								"#d3d692",
								"#e3afa9",
							]}
							particleCount={200}
							duration={2500}
							onComplete={() => {
								setIsSuccessfull(false);
							}}
						/>
					)}
					<span>
						E.
						<a href="mailto:hello@borzoistudio.com">
							hello@borzoistudio.com
						</a>
					</span>
				</div>
			</form>
		</section>
	);
}
