import React, { useEffect, useState } from "react";
import "../styles/lets-work-page/lets-work-styles.css";
import HeroSection from "../components/HeroSection";
import ContactInfoSection from "../components/lets-work-page/ContactInfoSection";
import BrandFormSection from "../components/lets-work-page/BrandFormSection";
import ServicesFormSection from "../components/lets-work-page/ServicesFormSection";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { render } from "@react-email/render";
import QuoteEmailComponent from "../components/email/QuoteEmailComponent";
import ConfettiExplosion from "react-confetti-explosion";
import { PuffLoader } from "react-spinners";
import emailjs from '@emailjs/browser';
import FullDogSit from "../assets/images/full-dog-sit.svg"

const EMAIL_SERVICE_ID = "service_ni8b8sq"
const TEMPLATE_ID = "lets_work_form"
const PUBLIC_KEY = "LEZdWIPHoURvozcUc"

const schema = yup
	.object()
	.shape({
		contact: yup.object().shape({
			full_name: yup.string().required("Full name is required."),
			email: yup.string().required("Email is required."),
			location: yup.string().required("Location is required."),
			phone: yup.object().shape({
				code: yup.string().required("Country code is required."),
				number: yup.string().required("Phone number is required."),
			}),
		}),
		brand: yup.object().shape({
			name: yup.string().required("Brand name is required."),
			industry: yup.string().required("Brand's industry is required."),
			description: yup
				.string()
				.required("Brand description is required."),
		}),
	})
	.required();

export default function LetsWorkPage() {
	const [isSuccessfull, setIsSuccessfull] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		defaultValues: {
			contact: {
				phone: {
					code: "961",
				},
			},
			services: {},
		},
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		document.title = "Borzoi Studio - Let's Work";
	}, []);

	const YES_NO_RESULT = (b) => (b ? "Yes" : "No");

	const onFormSubmit = async (data) => {
		const { contact, brand, services } = data;
		
		setIsLoading(true);
		emailjs
	      .send(
	        EMAIL_SERVICE_ID,
	        TEMPLATE_ID,
	        {
	          client_name: contact.full_name,
	          email: contact.email,
	          location: contact.location,
	          phone: `${contact.phone.code} ${contact.phone.number}`,
	          brand_name: brand.name,
	          industry: brand.industry,
	          website: brand.website || "No",
	          social_media_handle: brand.social_media_handle || "No",
	          brand_description: brand.description,
	          identity_creation: YES_NO_RESULT(services.branding.identity_creation),
	          logo_design: YES_NO_RESULT(services.branding.logo_design),
	          branding_other: YES_NO_RESULT(services.branding.other),
	          branding_deliverables_needed: services.branding.deliverables_needed || "No deliverables mentioned.",
	          website_design: YES_NO_RESULT(services.ui_ux.website_design),
	          website_development: YES_NO_RESULT(services.ui_ux.website_development),
	          app_design: YES_NO_RESULT(services.ui_ux.app_design),
	          app_development: YES_NO_RESULT(services.ui_ux.app_development),
	          social_media_design: YES_NO_RESULT(services.social_media.design),
	          social_media_management: YES_NO_RESULT(services.social_media.management),
	          social_media_content_creation: YES_NO_RESULT(services.social_media.content_creation),
	          social_media_copywriting: YES_NO_RESULT(services.social_media.copywriting),
	          social_media_digital_marketing: YES_NO_RESULT(services.social_media.digital_marketing),
	          social_media_other: YES_NO_RESULT(services.social_media.other),
	          social_media_deliverables_needed: services.social_media.deliverables_needed || "No deliverables mentioned.",
	          production_photography: YES_NO_RESULT(services.production.photography),
	          production_videography: YES_NO_RESULT(services.production.videography),
	          production_photo_editing: YES_NO_RESULT(services.production.photo_editing),
			  production_video_editing: YES_NO_RESULT(services.production.video_editing),
			  production_audio_production: YES_NO_RESULT(services.production.audio_production),
			  services_additional_notes: services.additional_notes || "No additional notes.",
	        },
	        PUBLIC_KEY,
	      ).then(()=>{
	      	setIsLoading(false);
			setIsSuccessfull(true);
		}).catch((e) => {
			setIsLoading(false);
			alert("Something went wrong sending the email.");
		})

		// const emailHtml = render(<QuoteEmailComponent data={data} />);
		// const emailParams = {
		// 	Source: "hello@borzoistudio.com",
		// 	Destination: {
		// 		ToAddresses: ["elienzgheib@gmail.com"],
		// 	},
		// 	Message: {
		// 		Body: {
		// 			Html: {
		// 				Charset: "UTF-8",
		// 				Data: emailHtml,
		// 			},
		// 		},
		// 		Subject: {
		// 			Charset: "UTF-8",
		// 			Data: `${data.contact.full_name} requested a quote!`,
		// 		},
		// 	},
		// };
	};

	return (
		<>
			<HeroSection
				text1={"request"}
				text2={"a quote"}
				arrowDownHyperlink={"#contact-info"}
				heroImage={FullDogSit}
			/>
			<form onSubmit={handleSubmit(onFormSubmit)}>
				<ContactInfoSection register={register} errors={errors} />
				<BrandFormSection register={register} errors={errors} />
				<ServicesFormSection register={register} errors={errors} />
				<div className="request-quotation-submit-btn">
					{/*<Button htmlFor="request-a-quotation" loading={isLoading}>
						Request quotation
					</Button>
					<input
						type="submit"
						id="request-a-quotation"
						value="Request quotation"
						hidden
					/>*/}
					<input
						type="submit"
						id="request-a-quotation"
						value="Request quotation"
						style={{color: isLoading ? 'transparent':'#fff'}}
					/>
					<div className="loader-wrapper">
						<PuffLoader
							size={45}
							color="#fff"
							loading={isLoading}
							speedMultiplier={1.1}
						/>
						</div>
					{isSuccessfull && (
						<ConfettiExplosion
							colors={[
								"#dd9c94",
								"#fcb3aa",
								"#111",
								"#eee",
								"#d3d692",
								"#e3afa9",
							]}
							particleCount={200}
							duration={2500}
							onComplete={() => {
								setIsSuccessfull(false);
							}}
						/>
					)}
				</div>
			</form>
		</>
	);
}
