import React, { useEffect, useState, useRef } from "react";
import "../styles/hero-section.css";

import InstagramIcon from "../assets/images/instagram-icon.svg";
import LinkedinIcon from "../assets/images/linkedin-icon.svg";
import BehanceIcon from "../assets/images/behance-icon.svg";
import ArrowDownCircleIcon from "../assets/images/arrow-down-circle-icon.svg";

const FRICTION = 50;

export default function HeroSection({ text1, text2, arrowDownHyperlink, heroImage }) {
	const [animateArrow, setAnimateArrow] = useState(null);

	const circle1Ref = useRef();
	const circle2Ref = useRef();

	useEffect(() => {
		runAnimation();
		document.addEventListener("mousemove", parallaxFunction);

		return () => {
			document.removeEventListener("mousemove", parallaxFunction);
		};
	}, []);

	const parallaxFunction = (e) => {
		const circle1Ratio = circle1Ref.current.getAttribute("data-ratio");
		let x = (e.clientX * circle1Ratio) / FRICTION;
		let y = (e.clientY * circle1Ratio) / FRICTION;
		circle1Ref.current.style.transform = `translateX(${x}px) translateY(${y}px)`;

		const circle2Ratio = circle2Ref.current.getAttribute("data-ratio");
		x = (e.clientX * circle2Ratio) / FRICTION;
		y = (e.clientY * circle2Ratio) / FRICTION;
		circle2Ref.current.style.transform = `translateX(${x}px) translateY(${y}px)`;
	};

	const runAnimation = () => {
		setAnimateArrow(true);
		setTimeout(() => {
			setAnimateArrow(false);
			setTimeout(runAnimation, 2000);
		}, 1000);
	};

	return (
		<section className="hero-section">
			<div className="full-dog-wrapper">
				<img
					src={heroImage}
					alt="Black illustration of the borzoi breed"
				/>
				<div
					ref={circle1Ref}
					data-ratio="2"
					className="full-dog-wrapper-floating-circles floating-circle-1"
				></div>
				<div
					ref={circle2Ref}
					data-ratio="3"
					className="full-dog-wrapper-floating-circles floating-circle-2"
				></div>
			</div>
			<h1 className="hero-text text-borzoi">{text1}</h1>
			<h1 className="hero-text text-studio">{text2}</h1>
			<div className="socials-wrapper">
				<a
					href="https://www.instagram.com/borzoistudio"
					target="_blank"
					rel="noreferrer"
				>
					<img src={InstagramIcon} alt="Instagram icon" />
				</a>
				<a
					href="https://www.linkedin.com/company/borzoistudio/"
					target="_blank"
					rel="noreferrer"
				>
					<img src={LinkedinIcon} alt="LinkedIn icon" />
				</a>
				<a
					href="https://www.behance.net/borzoistudio"
					target="_blank"
					rel="noreferrer"
				>
					<img src={BehanceIcon} alt="Behance Icon" />
				</a>
			</div>
			<a
				className={`arrow-down-circle ${
					animateArrow ? "animated" : ""
				}`}
				href={arrowDownHyperlink}
			>
				<img src={ArrowDownCircleIcon} alt="Arrow down button" />
			</a>
			<div className="blob blob1"></div>
			<div className="blob blob2"></div>
		</section>
	);
}
