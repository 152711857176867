import React, { useEffect, useState } from "react";
import "../../styles/lets-work-page/contact-info-section.css";
import country_codes_json from "../../data/countries_codes.json";

export default function ContactInfoSection({ register, errors }) {
	const [phoneCodes, setPhoneCodes] = useState([]);

	useEffect(() => {
		setPhoneCodes(country_codes_json);
	}, []);

	return (
		<section className="contact-info-section" id="contact-info">
			<div className="heading-wrapper">
				<h1>
					Contact
					<br />
					Info
				</h1>
				<div className="heading-bar right"></div>
			</div>

			<div className="contact-info-form">
				<div className="form-input-section">
					<label>
						<span className="asterisc">Full Name</span>
					</label>
					<div
						className={`input-wrapper ${
							errors.contact?.full_name ? "error" : ""
						}`}
					>
						<input type="text" {...register("contact.full_name")} />
					</div>
				</div>
				<div className="form-input-section">
					<label>
						<span className="asterisc">Email</span>
					</label>
					<div
						className={`input-wrapper ${
							errors.contact?.email ? "error" : ""
						}`}
					>
						<input type="text" {...register("contact.email")} />
					</div>
				</div>
				<div className="form-input-section">
					<label>
						<span className="asterisc">Location</span>
					</label>
					<div
						className={`input-wrapper ${
							errors.contact?.location ? "error" : ""
						}`}
					>
						<input type="text" {...register("contact.location")} />
					</div>
				</div>
				<div className="form-input-section">
					<label>
						<span className="asterisc">Phone Number</span>
					</label>
					<div className="phone-number-inputs-wrapper">
						<div className={`input-wrapper phone-code`}>
							<select {...register("contact.phone.code")}>
								{phoneCodes
									?.sort((a, b) =>
										a.name.localeCompare(b.name)
									)
									.map((item, index) => {
										return (
											<option
												value={item.code}
												key={item.iso}
												selected={item.code === "961"}
											>
												{item.code} - {item.name}
											</option>
										);
									})}
							</select>
						</div>
						<div
							className={`input-wrapper ${
								errors.contact?.phone?.number ? "error" : ""
							}`}
						>
							<input
								type="text"
								{...register("contact.phone.number")}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
