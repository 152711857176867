import React, { useState } from "react";
import "../styles/header.css";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import { motion } from "framer-motion";

const navigationsList = [
	{ label: "About Us", anchorLink: "/#about" },
	{ label: "Services", anchorLink: "/#services" },
	{ label: "Work", anchorLink: "/#work" },
	{ label: "Team", anchorLink: "/#team" },
	{ label: "Clients", anchorLink: "/#clients" },
	{ label: "Contact Us", anchorLink: "/#contact" },
	{ label: "Let's Work!", anchorLink: "/lets-work" },
];

export default function Header() {
	const [burgerMenuActive, setBurgerMenuActive] = useState(false);
	const { pathname } = useLocation();

	const handleBurgerClick = () => {
		setBurgerMenuActive(!burgerMenuActive);
	};

	return (
		<div className="header" id="home">
			<div>
				<a href="/">
					<img src={Logo} className="logo" alt="Borzoi Logo" />
				</a>
			</div>
			<div className="desktop">
				<ul className={`navigations ${pathname === "/lets-work" ? 'hidden':''}`}>
					{navigationsList.map((item, index) => {
						if (item.label === "Let's Work!") return null;

						return (
							<li key={index}>
								<a href={item.anchorLink}>{item.label}</a>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="desktop">
				<Link to="/lets-work">
					<div className={`lets-work ${pathname === "/lets-work" ? 'active':''}`}>Let's work!</div>
				</Link>
			</div>
			<div className="mobile">
				<label className="hamburger">
					<input
						type="checkbox"
						checked={burgerMenuActive}
						onChange={handleBurgerClick}
					/>
					<svg viewBox="5 0 24 32">
						<path
							className="line line-top-bottom"
							d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
						></path>
						<path className="line" d="M7 16 27 16"></path>
					</svg>
				</label>
			</div>
			<div
				className={`hamburger-content ${
					burgerMenuActive ? "active" : ""
				}`}
			>
				<motion.ul>
					{burgerMenuActive &&
						navigationsList.map((item, index) => (
							<motion.li
								key={index}
								className="hamburger-item"
								initial={{ opacity: 0, x: -50 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{
									duration: 2,
									delay: index * 0.1,
									type: "spring",
									stiffness: 260,
									damping: 20,
								}}
							>
								<a
									onClick={handleBurgerClick}
									href={item.anchorLink}
								>
									{item.label}
								</a>
							</motion.li>
						))}
					{!burgerMenuActive &&
						navigationsList.map((item, index) => (
							<motion.li
								key={index}
								className="hamburger-item"
								animate={{ opacity: 0, x: -50 }}
								initial={{ opacity: 1, x: 0 }}
								transition={{
									duration: 2,
									delay:
										(navigationsList.length - 1 - index) *
										0.1,
									type: "spring",
									stiffness: 260,
									damping: 20,
								}}
							>
								<a
									onClick={handleBurgerClick}
									href={item.anchorLink}
								>
									{item.label}
								</a>
							</motion.li>
						))}
				</motion.ul>
			</div>
		</div>
	);
}
