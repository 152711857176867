import React from "react";
import "../../styles/main-page/our-work-section.css";
import "../../styles/button.css";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setWorkModalState } from "../../redux/slices/modals-slice";

export default function OurWorkSection() {
	const dispatch = useDispatch();

	return (
		<section className="our-work-section" id="work">
			<h3>Our work</h3>
			<br />
			<div className="heading-wrapper">
				<h1>
					Ready to be
					<br />
					amazed?
				</h1>
				<div
					className="heading-bar right"
					style={{ transform: "scaleX(1.15)" }}
				></div>
			</div>

			<div className="grid-area">
				<div
					onClick={() =>
						dispatch(
							setWorkModalState({
								open: true,
								title: "NokNok",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/dd013d165057241.6400cbfed6a0e.jpg",
							})
						)
					}
					className="grid-item"
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/6ccad0165057241.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/6ccad0165057241.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/6ccad0165057241.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="NokNok cover"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() =>
						dispatch(
							setWorkModalState({
								open: true,
								title: "Traveln.ai",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/97446f184499701.6553465fe9c47.jpg",
							})
						)
					}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/3aae39184499701.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/3aae39184499701.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/3aae39184499701.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="Traveln.ai cover"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() => {
						dispatch(
							setWorkModalState({
								open: true,
								title: "Shivalila",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/857d58181506431.651d3e389db16.jpg",
							})
						);
					}}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/ec5cf1181506431.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/ec5cf1181506431.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/ec5cf1181506431.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="Shivalila cover"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() => {
						dispatch(
							setWorkModalState({
								open: true,
								title: "Nouvo Properties",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/16dbf7166498021.6419859b6575b.jpg",
							})
						);
					}}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/81cc42166498021.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/81cc42166498021.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/81cc42166498021.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="Nouvo Properties"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() => {
						dispatch(
							setWorkModalState({
								open: true,
								title: "Widea Shop",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/2efbe1161053689.63bed23c95519.jpg",
							})
						);
					}}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/b67ab5161053689.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/b67ab5161053689.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/b67ab5161053689.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="Widea Shop"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() => {
						dispatch(
							setWorkModalState({
								open: true,
								title: "WEARWOLF",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/fee67f160970755.63bd99414b4f7.jpg",
							})
						);
					}}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/0f518d160970755.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/0f518d160970755.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/0f518d160970755.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="WEARWOLF"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() => {
						dispatch(
							setWorkModalState({
								open: true,
								title: "Legacy Trims",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/060783158548055.638df78941ab7.jpg",
							})
						);
					}}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/3c887e158548055.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/3c887e158548055.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/3c887e158548055.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="Legacy Trims"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() => {
						dispatch(
							setWorkModalState({
								open: true,
								title: "Free2Go",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/a4d362158226831.63886eb580d78.jpg",
							})
						);
					}}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/f869a9158226831.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/f869a9158226831.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/f869a9158226831.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="Free2Go"
					/>
				</div>
				<div
					className="grid-item"
					onClick={() => {
						dispatch(
							setWorkModalState({
								open: true,
								title: "One Detergent",
								imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/c525ce158498965.638d0bdf6f89c.jpg",
							})
						);
					}}
				>
					<img
						srcset="https://mir-s3-cdn-cf.behance.net/projects/202/c40e99158498965.Y3JvcCw4MDgsNjMyLDAsMA.jpg 202w, https://mir-s3-cdn-cf.behance.net/projects/404/c40e99158498965.Y3JvcCw4MDgsNjMyLDAsMA.jpg 404w, https://mir-s3-cdn-cf.behance.net/projects/808/c40e99158498965.Y3JvcCw4MDgsNjMyLDAsMA.jpg 808w"
						type="image/jpg"
						alt="One Detergent"
					/>
				</div>
			</div>

			<div className="more-button-wrapper">
				{/*<Button
					type="hyperlink"
					path="https://www.behance.net/borzoistudio"
					target="_blank"
					style={{ margin: "3rem 0" }}
				>
					More on Behance
				</Button>*/}
			<Link to="https://www.behance.net/borzoistudio" target="_blank">More on Behance</Link>
			</div>
		</section>
	);
}
