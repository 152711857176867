import React from "react";
import "../../styles/lets-work-page/services-form-section.css";
import CustomCheckbox from "../CustomCheckbox";

export default function ServicesFormSection({ register }) {
	return (
		<section className="services-form-section">
			<div className="heading-wrapper">
				<h1>
					Services
					<br />
					Needed
				</h1>
				<div className="heading-bar right"></div>
			</div>
			<div className="services-form">
				<div className="form-input-section">
					<label className="title-label">Branding</label>
					<div className="form-input-section-groups">
						<div className="inputs-group">
							<label htmlFor="identity_creation">
								<input
									type="checkbox"
									id="identity_creation"
									defaultChecked={false}
									{...register(
										"services.branding.identity_creation"
									)}
								/>
								<CustomCheckbox />
								Identity Creation
							</label>

							<label htmlFor="logo_design">
								<input
									type="checkbox"
									id="logo_design"
									defaultChecked={false}
									{...register(
										"services.branding.logo_design"
									)}
								/>
								<CustomCheckbox />
								Logo Design
							</label>

							<label htmlFor="other_branding">
								<input
									type="checkbox"
									id="other_branding"
									defaultChecked={false}
									{...register("services.branding.other")}
								/>
								<CustomCheckbox />
								Other
							</label>
						</div>
						<div className="inputs-group textarea-group">
							<textarea
								placeholder="Deliverables Needed"
								{...register(
									"services.branding.deliverables_needed"
								)}
							></textarea>
						</div>
					</div>
				</div>

				<div className="form-input-section ">
					<label className="title-label">UI / UX</label>
					<div className="form-input-section-groups">
						<div className="inputs-group">
							<label htmlFor="website_design">
								<input
									type="checkbox"
									id="website_design"
									defaultChecked={false}
									{...register(
										"services.ui_ux.website_design"
									)}
								/>
								<CustomCheckbox />
								Website Design
							</label>

							<label htmlFor="website_development">
								<input
									type="checkbox"
									id="website_development"
									defaultChecked={false}
									{...register(
										"services.ui_ux.website_development"
									)}
								/>
								<CustomCheckbox />
								Website Development
							</label>

							<label htmlFor="app_design">
								<input
									type="checkbox"
									id="app_design"
									defaultChecked={false}
									{...register("services.ui_ux.app_design")}
								/>
								<CustomCheckbox />
								App Design
							</label>

							<label htmlFor="app_development">
								<input
									type="checkbox"
									id="app_development"
									defaultChecked={false}
									{...register(
										"services.ui_ux.app_development"
									)}
								/>
								<CustomCheckbox />
								App Development
							</label>
						</div>
						<div className="inputs-group textarea-group">
							<textarea
								placeholder="Website / App Description"
								{...register("services.ui_ux.description")}
							></textarea>
						</div>
					</div>
				</div>

				<div className="form-input-section span-2">
					<label className="title-label">Social Media</label>
					<div className="form-input-section-groups">
						<div className="inputs-group">
							<label htmlFor="social-media-design">
								<input
									type="checkbox"
									id="social-media-design"
									defaultChecked={false}
									{...register(
										"services.social_media.design"
									)}
								/>
								<CustomCheckbox />
								Design
							</label>

							<label htmlFor="social-media-management">
								<input
									type="checkbox"
									id="social-media-management"
									defaultChecked={false}
									{...register(
										"services.social_media.management"
									)}
								/>
								<CustomCheckbox />
								Management
							</label>

							<label htmlFor="social-media-content-creation">
								<input
									type="checkbox"
									id="social-media-content-creation"
									defaultChecked={false}
									{...register(
										"services.social_media.content_creation"
									)}
								/>
								<CustomCheckbox />
								Content Creation
							</label>

							<label htmlFor="social-media-copywriting">
								<input
									type="checkbox"
									id="social-media-copywriting"
									defaultChecked={false}
									{...register(
										"services.social_media.copywriting"
									)}
								/>
								<CustomCheckbox />
								Copywriting
							</label>

							<label htmlFor="social-media-digital-marketing">
								<input
									type="checkbox"
									id="social-media-digital-marketing"
									defaultChecked={false}
									{...register(
										"services.social_media.digital_marketing"
									)}
								/>
								<CustomCheckbox />
								Digital Marketing
							</label>

							<label htmlFor="social-media-other">
								<input
									type="checkbox"
									id="social-media-other"
									defaultChecked={false}
									{...register("services.social_media.other")}
								/>
								<CustomCheckbox />
								Other
							</label>
						</div>
						<div className="inputs-group textarea-group">
							<textarea
								placeholder="Deliverables Needed"
								{...register(
									"services.social_media.deliverables_needed"
								)}
							></textarea>
						</div>
					</div>
				</div>

				<div
					className="form-input-section span-2"
					style={{ height: "unset" }}
				>
					<label
						className="title-label"
						style={{ marginBottom: "20px" }}
					>
						Production
					</label>
					<div className="form-input-section-groups">
						<div className="inputs-group production-group">
							<label htmlFor="photography">
								<input
									type="checkbox"
									id="photography"
									defaultChecked={false}
									{...register(
										"services.production.photography"
									)}
								/>
								<CustomCheckbox />
								Photography
							</label>

							<label htmlFor="videography">
								<input
									type="checkbox"
									id="videography"
									defaultChecked={false}
									{...register(
										"services.production.videography"
									)}
								/>
								<CustomCheckbox />
								Videography
							</label>

							<label htmlFor="photo-editing">
								<input
									type="checkbox"
									id="photo-editing"
									defaultChecked={false}
									{...register(
										"services.production.photo_editing"
									)}
								/>
								<CustomCheckbox />
								Photo Editing
							</label>

							<label htmlFor="video-editing">
								<input
									type="checkbox"
									id="video-editing"
									defaultChecked={false}
									{...register(
										"services.production.video_editing"
									)}
								/>
								<CustomCheckbox />
								Video Editing
							</label>

							<label htmlFor="audio-production">
								<input
									type="checkbox"
									id="audio-production"
									defaultChecked={false}
									{...register(
										"services.production.audio_production"
									)}
								/>
								<CustomCheckbox />
								Audio Production
							</label>
						</div>
					</div>
				</div>

				<div className="form-input-section span-2">
					<label className="title-label">Additional Notes</label>
					<div className="form-input-section-groups">
						<div className="inputs-group textarea-group">
							<textarea
								{...register("services.additional_notes")}
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
