import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import OurWorkModal from "./modal/OurWorkModal";
import { useSelector } from "react-redux";

export default function Layout() {
	const workModalState = useSelector(
		(state) => state.modalsSlice.workModalState
	);

	return (
		<>
			<Header />
			{workModalState.open && (
				<OurWorkModal
					title={workModalState.title}
					imgSrc={workModalState.imgSrc}
				/>
			)}
			<Outlet />
			<Footer />
		</>
	);
}
